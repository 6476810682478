/*ITEMS COLLECTION*/
import { buildCollection, buildProperty, EntityReference } from "firecms";
import { localeCollection } from "./locales";

type Item = {
  title: string;
  type: string;
  description: string,
  profiles: EntityReference[];
  categories: EntityReference[];
  categories_strings: [];
  root: EntityReference;
  color: string;
  icon: string;
  aka: string;
}

export const items = buildCollection<Item>({
  name: "Items",
  icon: "Label",
  group: "Managed Content",
  singularName: "Item",
  path: "items",
  customId: true,
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
  subcollections: [
    localeCollection,
  ],
  properties: {
    title: {
      name: "Title",
      dataType: "string",
      validation: { required: true },
    },
    type: {
      name: "Type",
      description: "Select the appropriate type",
      dataType: "string",
      enumValues: {
        ingredient: "Ingredient",
        category: "Category",
        creationCategory: "Creation Category",
        creation: "Creation",
        profile: "Profile",
        region: "Region",
        style: "Style",
        technique: "Technique",
        diet: "Diet",
        event: "Event",
        season: "Season",
        meal: "Meal",
        internal: "Internal",
      },
      validation: { required: true },
    },
    description: {
      name: "Description",
      dataType: "string",
      multiline: true,
    },
    profiles: {
      name: "Profiles",
      description: "Choose one or more profiles",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
        forceFilter: {
          type: ["==", "profile"],
        },
      },
    },
    categories: {
      name: "Categories",
      description: "Choose one or more categories",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
        forceFilter: {
          type: ["in", ['category','region']],
        },
      },
    },
    categories_strings: {
      name: "Categories Strings (for search index)",
      description: "Category names, lowercase, no dashes",
      dataType: "array",
      of: {
        dataType: "string",
      },
    },
    root: {
      name: "Root",
      description: "Choose a root if appropriate",
      dataType: "reference",
      path: "items",
    },
    aka: {
      name: "AKA",
      description: "Enter any alternate names",
      dataType: "string",
    },
    color: {
      name: "Color Hex",
      description: "Hex format like #ffffff",
      dataType: "string",
    },
    icon: buildProperty({ // The `buildProperty` method is a utility function used for type checking
      name: "Icon",
      dataType: "string",
      storage: {
        storagePath: "images",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      },
    }),
  },
});
