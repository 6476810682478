/*LISTS COLLECTION*/
import { buildCollection, EntityReference } from "firecms";

type List = {
  title: string;
  //description: string;
  postsTitle: string;
  posts: EntityReference[];
  usersTitle: string;
  users: EntityReference[];
  itemsFeatured1Title: string;
  itemsFeatured1: EntityReference[];
  itemsFeatured2Title: string;
  itemsFeatured2: EntityReference[];
  itemsTitle: string;
  items: EntityReference[];
  // itemGroups: Map<String,EntityReference[]>;
  combinationsTitle: string;
  combinations: EntityReference[]; 
}

export const lists = buildCollection<List>({
  name: "Lists",
  icon: "FolderSpecial",
  group: "Managed Content",
  singularName: "List",
  path: "lists",
  customId: true,
  textSearchEnabled: false,
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: false,
  }),
  properties: {
    title: {
      name: "Title",
      dataType: "string",
      validation: { required: true },
    },
    /*
    description: {
      name: "Description",
      dataType: "string",
      multiline: true,
    },
    */
    postsTitle: {
      name: "Posts Title",
      dataType: "string",
      validation: { required: false },
    },
    posts: {
      name: "Posts",
      description: "Choose the posts for this list",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "posts",
      },
    },
    usersTitle: {
      name: "Users Title",
      dataType: "string",
      validation: { required: false },
    },
    users: {
      name: "Users",
      description: "Choose the users for this list",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "users",
      },
    },
    itemsFeatured1Title: {
      name: "Items Featured 1 Title",
      dataType: "string",
      validation: { required: false },
    },
    itemsFeatured1: {
      name: "Items Featured 1",
      description: "Choose the items for this list",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
      },
    },
    itemsFeatured2Title: {
      name: "Items Featured 2 Title",
      dataType: "string",
      validation: { required: false },
    },
    itemsFeatured2: {
      name: "Items Featured 2",
      description: "Choose the items for this list",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
      },
    },
    itemsTitle: {
      name: "Items Title",
      dataType: "string",
      validation: { required: false },
    },
    items: {
      name: "Items",
      description: "Choose the items for this list",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "items",
      },
    },
    /*
    itemGroups: {
      name: "Item Groups",
      dataType: "map",
      keyValue: true,
    },
    */
    combinationsTitle: {
      name: "Combinations Title",
      dataType: "string",
      validation: { required: false },
    },
    combinations: {
      name: "Combinations",
      description: "Choose the combination posts for this list",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "posts",
      },
    },
  },
});